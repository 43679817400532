import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import {Fragment} from "react";

export default function App({ Component, pageProps }: AppProps) {
  return (
  	<Fragment>
			<Head>
				<link rel="icon" href="/favicon.ico" />
				<meta name="description" content="Paytr payment provider" />
		    <meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
  		<Component {...pageProps} />
  	</Fragment>
  )
}
